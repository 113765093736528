import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModDotList from "../../../components/Chronology/Modules/ModDotList";

export const frontmatter = {
  title: "Día 85",
  week: "Semana 13",
  day: "06",
  month: "Jun",
  monthNumber: "06",
  date: "2020-06-06",
  path: "/cronologia/semana-13#dia-06-jun/",
};

const Day85 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En los últimos siete días se han diagnosticado en{" "}
          <strong>España</strong> 1.966 casos mediante pruebas PCR. En ese mismo
          periodo, 169 personas han requerido hospitalización (8,6% de los
          diagnósticos), de los que 15 han sido ingresos en la UCI, y se han
          producido 67 fallecimientos.
        </ModText>
        <ModText>
          El Boletín Oficial del Estado ha publicado hoy una{" "}
          <InlineLink link="https://www.boe.es/boe/dias/2020/06/06/pdfs/BOE-A-2020-5795.pdf">
            Orden
          </InlineLink>{" "}
          mediante la cual se flexibilizan determinadas restricciones para las
          unidades territoriales en fase 2 y 3, que estaban recogidas en órdenes
          anteriores.
        </ModText>
        <ModText>
          Entre las medidas de flexibilización establecidas, podemos encontrar:
        </ModText>
        <ModDotList>
          Se eliminan las restricciones a la ocupación de los vehículos respecto
          del total de plazas sentadas, para los transportes en autobús y
          ferroviarios, así como para los transportes terrestres colectivos de
          ámbito urbano y periurbano.
        </ModDotList>
        <ModDotList>
          Se prevé la reapertura de plazas, recintos e instalaciones taurinas al
          aire libre desde la Fase 2.
        </ModDotList>
        <ModDotList>
          Se permite la apertura de discotecas y bares de ocio nocturno en la
          Fase 3. Además, se podrá consumir dentro de los locales de hostelería
          y restauración.
        </ModDotList>
        <ModImage
          src="/images/svg/discoteca-local-nocturno.svg"
          alt="AtributoAlt"
        />
      </ContentRight>
    </LayoutDay>
  );
};
export default Day85;
